<template>
    <div @click="popupClose">
      <div class="user-index hidden-sm-and-down">
        <div class="title">
          Personal Homepage
        </div>
        <div class="content">
          <div class="photo">
            <el-avatar :size="133" :src="photo"></el-avatar>
          </div>
          <div class="name">
            <div class="main-name">
              <img src="../../assets/images/G/name-bg.png" alt="">
              <span @click="test">{{ name == undefined || name == null || name == ''? userName:name}}</span>
            </div>
<!--            <div class="sign-name">绝世大美妞</div>-->
          </div>
          <div class="act-card">
            <ul>
              <li @click="cardBtn(1)">
                  <p>Live Events</p>
                  <span>{{num1}}</span>
              </li>
              <li @click="cardBtn(2)">
                  <p>Registered Events</p>
                  <span>{{num2}}</span>
              </li>
              <li @click="cardBtn(3)">
                  <p>Favourite Events</p>
                  <span>{{num3}}</span>
              </li>
              <li @click="cardBtn(4)">
                  <p>Following Organizers</p>
                  <span>{{num4}}</span>
              </li>
              <li class="wx-program">
                <p>Badge</p>
                <span>{{badgeCount}}</span>
              </li>
              <li class="wx-program">
                <p>Points</p>
                <span>{{pointsCount}}</span>
              </li>
              <li class="wx-program">
                <p>Comments</p>
                <span>{{commentCount}}</span>
              </li>
              <li class="wx-program">
                <p>Exchange</p>
                <span>{{exchangeCount}}</span>
              </li>
            </ul>
          </div>
          <div class="power-box">
            <ul>
              <li @click.stop="cardBtn(0)">
                <el-col :span="2" style="text-align: center">
                  <img style="vertical-align: middle;" src="../../assets/images/G/wx-propram.png" alt="">
                </el-col>
                <el-col :span="4">
                  <span>Mini Program</span>
                </el-col>
                <el-col :span="18">
                  <p>"CP Zone JustShenzhen" Miniprogram is a handy partner for "Just Shenzhen" Urban Discovery Route, go check it out!</p>
                </el-col>
                <transition name="popup-fade">
                <div class="popup-code" v-show="popupShow">
                  <img src="../../assets/images/G/mini-code.jpg" alt="">
                  <div class="close-btn" @click.stop="popupClose">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
                </transition>
              </li>
              <li @click="cardBtn(5)">
                <el-col :span="2" style="text-align: center">
                  <img src="../../assets/images/G/user.png" alt="">
                </el-col>
                <el-col :span="4">
                  <span>My Account</span>
                </el-col>
                <el-col :span="18">
                  <p>Fill in the data to help boost trust.</p>
                </el-col>
              </li>
              <li @click="cardBtn(6)">
                <el-col :span="2" style="text-align: center">
                  <img src="../../assets/images/G/authen.png" alt="">
                </el-col>
                <el-col :span="4">
                  <span>Organizers Certification</span>
                </el-col>
                <el-col :span="18">
                  <p>Once certified as a organizer, you can create event freely and get <img src="../../assets/images/Q/Q1/vip-icon.png" alt="" style="vertical-align: middle;"> certification</p>
                </el-col>
              </li>
              <li @click="cardBtn(7)">
                <el-col :span="2" style="text-align: center">
                  <img src="../../assets/images/G/password.png" alt="">
                </el-col>
                <el-col :span="4">
                  <span>Change Password</span>
                </el-col>
                <el-col :span="18">
                  <p>Change your password regularly to secure your account.</p>
                </el-col>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="m-user-index hidden-md-and-up">
        <div class="act-card">
          <ul>
            <li @click="cardBtn(1)">
              <p>Live Events</p>
              <span>{{num1}}</span>
            </li>
            <li @click="cardBtn(2)">
              <p>Registered Events</p>
              <span>{{num2}}</span>
            </li>
            <li @click="cardBtn(3)">
              <p>Favourite Events</p>
              <span>{{num3}}</span>
            </li>
            <li @click="cardBtn(4)">
              <p>Following Organizers</p>
              <span>{{num4}}</span>
            </li>
            <li class="wx-program">
              <p>Badge</p>
              <span>{{badgeCount}}</span>
            </li>
            <li class="wx-program">
              <p>Points</p>
              <span>{{pointsCount}}</span>
            </li>
            <li class="wx-program">
              <p>Comments</p>
              <span>{{commentCount}}</span>
            </li>
            <li class="wx-program">
              <p>Redeem</p>
              <span>{{exchangeCount}}</span>
            </li>
          </ul>
        </div>
        <div class="power-box">
          <ul>
            <li @click.stop="cardBtn(0)">
              <el-col :span="4">
                <img class="power-icon" style="vertical-align: middle;" src="../../assets/images/G/wx-propram.png" alt="">
              </el-col>
              <el-col :span="20">
                <div class="title">
                  Mini Program
                </div>
                <p>"CP Zone JustShenzhen" Miniprogram is a handy partner for "Just Shenzhen" Urban Discovery Route, go check it out!</p>
              </el-col>
              <transition name="popup-fade">
              <div class="popup-code" v-show="popupShow">
                <img src="../../assets/images/G/mini-code.jpg" alt="">
                <div class="close-btn" @click.stop="popupClose">
                  <i class="el-icon-close"></i>
                </div>
              </div>
              </transition>
            </li>
            <li @click="cardBtn(5)">
              <el-col :span="4">
                <img class="power-icon" src="../../assets/images/G/user.png" alt="">
              </el-col>
              <el-col :span="20">
                <div class="title">
                  My Account
                </div>
                <p>Fill in the data to help boost trust.</p>
              </el-col>
            </li>
            <li @click="cardBtn(6)">
              <el-col :span="4">
                <img class="power-icon" src="../../assets/images/G/authen.png" alt="">
              </el-col>
              <el-col :span="20">
                <div class="title">
                  Organizers Certification
                </div>
                <p>Once certified as a organizer, you can create event freely and get <img src="../../assets/images/Q/Q1/vip-icon.png" alt=""> certification</p>
              </el-col>
            </li>
            <li @click="cardBtn(7)">
              <el-col :span="4">
                <img class="power-icon" src="../../assets/images/G/password.png" alt="">
              </el-col>
              <el-col :span="20">
                <div class="title">
                  Change Password
                </div>
                <p>Change your password regularly to secure your account.</p>
              </el-col>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>
<script>
import { initiatelist, colfunlist, joinfunlist, collectSponsor, getUserData } from '../../api'
import qs from 'qs'
export default {
  name: 'index',
  data () {
    return {
      name: '',
      userName: '',
      photo: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      num1: '',
      num2: '',
      num3: '',
      num4: '',
      popupShow: false,
      badgeCount: '-',
      pointsCount: '-',
      commentCount: '-',
      exchangeCount: '-'
    }
  },
  inject: ['reload'],
  methods: {
    test () {
      console.log(this.$store.state.userId)
    },
    popupClose () {
      this.popupShow = false
    },
    cardBtn (i) {
      if (i === 1) {
        this.$router.push('/userEN/eventadminEN')
      } else if (i === 2) {
        this.$router.push('/userEN/participateEN')
      } else if (i === 3) {
        this.$router.push('/userEN/collectionEN')
      } else if (i === 4) {
        this.$router.push('/userEN/sponsorcollectionEN')
      } else if (i === 5) {
        this.$router.push('/userEN/profileEN')
      } else if (i === 6) {
        this.$router.push('/userEN/authenticationEN')
      } else if (i === 7) {
        this.$router.push('/userEN/changepwdEN')
      } else if (i === 0) {
        this.popupShow = true
      }
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        if (sc.isSZSMT()) {
          console.log('在i深圳平台')
          this.$parent.linkIshenzhen()
        } else {
          console.log('不在i深圳平台内')
          this.$router.push('/LoginEN')
        }
      })
    } else {
      collectSponsor(userInfo.userId).then(res => {
        this.num4 = res.data.length
      })
      // let id = '47bc75a3494d478dbb251e68d4aeccf3'
      // let id2 = '592055c3510b42c999fcd9d238fdf768'
      // let name = 'hbh'
      initiatelist(userInfo.userId).then(res => {
        this.num1 = res.data.length
      })
      let data = {
        SPONSOR_ID: userInfo.userId
      }
      colfunlist(userInfo.userId).then(res => {
        this.num3 = res.data.length
      })
      joinfunlist(userInfo.userId).then(res => {
        let list = []
        for (let i = 0, len = res.data.length; i < len; i++) {
          if (res.data[i].EXAMINE == 'false') {
            list.push(res.data[i])
          } else if (res.data[i].EXAMINE == 'true') {
            list.push(res.data[i])
          }
        }
        this.num2 = list.length
      })
      getUserData(qs.stringify(data)).then(res => {
        // console.log(res)
        this.name = res.data.new.NAME
        if (res.data.new.IMAGE != undefined || res.data.new.IMAGE != null) {
          this.photo = res.data.new.IMAGE
        }
        if (res.data.new.isMember) {
          this.badgeCount = res.data.new.badgeCount
          this.pointsCount = res.data.new.integralCount
          this.commentCount = res.data.new.commentCount
          this.exchangeCount = res.data.new.exchangeCount
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped src="@/assets/css/CityGe.styl"></style>
<style lang="less">
  .popup-code{
    position: absolute;
    bottom: 58px;
    left: 30%;
    width: 3rem;
    padding: 0.3rem;
    background: #fff;
    box-shadow:0px 1px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: 666;
    cursor: initial;
    &:after{
      content: '';
      position: absolute;
      top: 3.1rem;
      left: 47%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid #fff;
    }
    img{
      width: 100%;
    }
    .close-btn{
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.4rem;
      cursor: pointer;
      transition: all 0.25s;
      &:hover{
        transform: scale(1.1);
      }
    }
  }
  .popup-fade-enter-active{
    animation: zoomIn;
    animation-duration: .5s;
    animation-fill-mode: both;
  }
  .popup-fade-leave-active {
    animation: zoomIn .4s reverse;
  }
  @keyframes zoomIn {
    0% {
      -webkit-transform: scale3d(.3, .3, .3);
      opacity: 0;
      transform: scale3d(.3, .3, .3)
    }

    50% {
      opacity: 1
    }
  }
  @media screen and (max-width: 990px) {
    .popup-code{
      width: 4.9rem;
      &:after{
        top: 5rem;
      }
      .close-btn{
        i{
          font-size: 0.6rem;
        }
      }
    }
  }
</style>
