import { render, staticRenderFns } from "./CityG1.vue?vue&type=template&id=48c635be&scoped=true&"
import script from "./CityG1.vue?vue&type=script&lang=js&"
export * from "./CityG1.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/CityGe.styl?vue&type=style&index=0&id=48c635be&lang=stylus&scoped=true&"
import style1 from "./CityG1.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c635be",
  null
  
)

export default component.exports